import { Link, graphql } from 'gatsby'
import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../components/en/Layout'
import Breadcrumbs from '../../components/Breadcrumbs'
import SEO from '../../components/SEO'

const cheerio = require('cheerio')

const Post = ({ data }) => {
  const post = data.postsEn
  const relatedPosts = data.allPostsRandomEn.edges
    .sort(() => 0.5 - Math.random())
    .slice(0, 3)

  let postContent = post.meta.content
  let postContentHTML = cheerio.load(postContent)

  if (postContentHTML('img').length > 0) {
    for (let i = 0; i < postContentHTML('img').length; i++) {
      let image = postContentHTML('img')[i]
      if (image.attribs.src) {
        image.attribs.srcset = ''

        image.attribs.src = image.attribs.src.replace(
          'historiaislamica.com.br',
          'historia-images.web.app'
        )
        image.attribs.src = image.attribs.src.replace(
          'cghil165d8n2uk2jf2a1qytm-wpengine.netdna-ssl.com',
          'historia-images.web.app'
        )
        image.attribs.src = image.attribs.src.replace(
          'hislamic.com',
          'hislamic-images.web.app'
        )
      }
    }
  }

  if (postContentHTML('a').length > 0) {
    for (let i = 0; i < postContentHTML('a').length; i++) {
      let link = postContentHTML('a')[i]
      if (link.attribs.href) {
        link.attribs.href = link.attribs.href.replace(
          'historiaislamica.com.br',
          'historiaislamica.com/pt'
        )
        link.attribs.href = link.attribs.href.replace(
          'hislamic.com',
          'historiaislamica.com/en'
        )
      }
    }
  }

  postContentHTML = postContentHTML.html()

  return (
    <Layout>
      <SEO
        title={post.name}
        description={post.meta.content.slice(0, 200)}
        post={true}
        image={post.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/en/${post.slug}`}
        category={post.category.name}
        date={post.date || post.createdAt}
        dateUpdated={post.updatedAt}
      />

      <div className="post">
        <div
          className="post-img"
          style={{
            background: `url(${post.imageUrl})`,
            backgroundSize: 'cover',
          }}
        ></div>

        <Breadcrumbs post={post} />

        <div className="post-header">
          <h1>{post.name}</h1>
          {post.author && (
            <small>
              Autor: <b>{post.author}</b>
            </small>
          )}
          <small>
            <FontAwesomeIcon icon={faClock} /> {post.date}
          </small>
        </div>

        <div className="post-share">
          <FacebookShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            quote={`${post.name} e outros conteúdos estão no História Islâmica`}
            hashtag="História Islâmica"
          >
            <FacebookIcon size={50} />
          </FacebookShareButton>

          <TwitterShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            title={`${post.name} e outros conteúdos estão no História Islâmica`}
            via="História Islâmica"
            hashtags={['História Islâmica']}
          >
            <TwitterIcon size={50} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            title={`${post.name} e outros conteúdos estão no História Islâmica`}
            separator=" - "
          >
            <WhatsappIcon size={50} />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            title={`${post.name} e outros conteúdos estão no História Islâmica`}
          >
            <TelegramIcon size={50} />
          </TelegramShareButton>
        </div>

        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: postContentHTML }}
        ></div>

        <div className="post-share post-mobile-share">
          <FacebookShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            quote={`${post.name} e outros conteúdos estão no História Islâmica`}
            hashtag="História Islâmica"
          >
            <FacebookIcon size={50} />
          </FacebookShareButton>

          <TwitterShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            title={`${post.name} e outros conteúdos estão no História Islâmica`}
            via="História Islâmica"
            hashtags={['História Islâmica']}
          >
            <TwitterIcon size={50} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            title={`${post.name} e outros conteúdos estão no História Islâmica`}
            separator=" - "
          >
            <WhatsappIcon size={50} />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/${post.slug}`}
            title={`${post.name} e outros conteúdos estão no História Islâmica`}
          >
            <TelegramIcon size={50} />
          </TelegramShareButton>
        </div>

        <div className="related-posts">
          <div className="related-posts-header">
            <h3>Related Posts</h3>
            <hr />
          </div>

          <div className="related-posts-list">
            {relatedPosts.map((post) => (
              <Link
                to={`/${post.node.language.slug}/${post.node.slug}`}
                key={post.node.id}
              >
                <div
                  className="related-post-img"
                  style={{
                    background: `url(${post.node.imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
                <h3>{post.node.name}</h3>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($id: String!) {
    postsEn(id: { eq: $id }) {
      name
      slug
      imageUrl
      author
      date(formatString: "MM/DD/YYYY", locale: "en-US")
      meta {
        content
      }
      language {
        slug
      }
      category {
        name
        slug
      }
    }

    allPostsRandomEn {
      edges {
        node {
          id
          name
          slug
          imageUrl
          language {
            slug
          }
        }
      }
    }
  }
`
